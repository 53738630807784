import React from "react";
import ContentContainer from "./contentContainer";
import { Box, Grid } from "@mui/material";
import PageTitle from "./pageTitle";
import { apiHostUrl } from '../../helpers';
import './g3SupportToolbox.css';

export default function G3SupportToolbox() {
    return (
        <ContentContainer>
            <PageTitle title='Support' />

            <Box component="main" sx={{ p: 3, width: '100%' }}>
                <Grid container className="support-toolbox">
                    <Grid item xs={6}>
                        <h3>G3 Support</h3>
                        <ul>
                            <li>
                                <a href={`${apiHostUrl}/api/v1/file/media/kvaser_drivers_setup.exe`} download>SmartCraft Interface</a>
                            </li>
                            <li>
                                <a href={`${apiHostUrl}/api/v1/file/media/G3ApiAccessTroubleshooterV2.zip`} download>Troubleshooter</a>
                            </li>
                            <li>
                                <a href={`${apiHostUrl}/api/v1/file/media/ndp48-web.exe`} download>DotNet 4.8</a>
                            </li>
                            <li>
                                <a href={`${apiHostUrl}/api/v1/file/media/dotnet_cleanup_tool.zip`} download>DotNet Cleanup</a>
                            </li>
                            <li>
                                <a href={`${apiHostUrl}/api/v1/file/media/G3_Remover.exe`} download>G3 Remover</a>
                            </li>
                            <li>
                                <a href={`${apiHostUrl}/api/v1/file/media/PerfMonFix.zip`} download>Perf Mon Fix</a>
                            </li>
                            <li>
                                <a href={`${apiHostUrl}/api/v1/file/media/G3 Serial Cable Driver.zip`} download>Serial Cable Drivers</a>
                            </li>
                            <li>
                                <a href={`${apiHostUrl}/api/v1/file/media/FwUpdateTool_4_31.zip`} download>Firmware</a>
                            </li>
                            <li>
                                <a href={`${apiHostUrl}/api/v1/file/media/aspnetcore-runtime-8.0.4-win-x86.exe`} download>ASP Fix</a>
                            </li>
                            <li>
                                <a href={`${apiHostUrl}/api/v1/file/media/SyncTestV3.zip`} download>Sync Test</a>
                            </li>
                        </ul>
                    </Grid>
                </Grid>
            </Box>
        </ContentContainer>
    );
}